import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { TypeDrupalLandingPageQueryResult } from "../../../utils/queries/drupal_landing_page";
import AmazeePage from "../../../components/layout";
import AmazeeIoMetadata from "../../../components/metadata";
import { PageTitle } from "../../../components/title/title";
import { AmazeeIOButton } from "../../../components/button/button";
import RichTextRender from "../../../utils/rich-text";
import Link from "gatsby-link";
import quoteIcon from "../../../../static/images/quotes.png";
import { Tick, TickCircle } from "../../../components/icons";

import './index.scss';


const DrupalLandingPage = ({ location }: { location: Location }) => {
  const { allPrismicDrupalLandingPage } = useStaticQuery<TypeDrupalLandingPageQueryResult>(DrupalPageQuery);
  const data = allPrismicDrupalLandingPage.edges[0].node.data;

  return (
    <AmazeePage id={''} classSet={''} location={location}>
      <PageTitle title={data.title || "Managed Drupal Hosting | amazee.io"} />

      <AmazeeIoMetadata
        title={data.meta_title || "Managed Drupal Hosting | amazee.io"}
        page_meta_description={data.page_meta_description}
        page_meta_thumbnail={data.page_meta_thumbnail}
        location={location}
      />
      
      <main id="drupal-landing-page" className="text-black">
        <section className="hero px-3 md:px-0 pt-11 pb-20" aria-labelledby="heading">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
            <div className="xl:w-11/12 mx-auto">
              <div className="grid md:grid-cols-2 items-center">
                <div className="xl:w-11/12">
                  <h1 className="text-3xl lg:text-4xl xl:text-5xl font-semibold text-black mb-10" id="heading">{data.hero_heading}</h1>
                  <p className="md:text-xl text-black font-light mb-10">{data.hero_subheading}</p>
                </div>
                <div className="lg:w-10/12">
                  <img src={data.hero_image1.url} alt={data.hero_image.alt} className="object-contain" />
                </div>
              </div>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
                {data.hero_features.map((feature, index) => (
                  <li key={index} className="text-black flex gap-x-1 items-center text-lg font-medium">
                    <div className="tick mr-2 rounded-full p-1">
                      <Tick />
                    </div>
                    <p className="mb-0">{feature.hero_feature_list}</p>
                  </li>
                ))}
                
              </ul>
              <div>
                <Link to={`/get-in-touch`} className="text-center md:text-left">
                <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto">
                  {data.hero_cta}
                </AmazeeIOButton>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="badge-gradient py-20" aria-labelledby="badge-heading">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto w-11/12 lg:w-auto">
            <div className="md:8/12 xl:w-full px-3 md:px-0 mx-auto">
              <h2 id="badge-heading" className="mb-5 md:mb-10 text-white text-center font-semibold text-2xl md:text-3.5xl md:leading-10">{data.badge_heading}</h2>
              <div className="flex flex-wrap justify-center md:gap-10 gap-5 lg:w-10/12 2xl:w-3/4 mx-auto">
                {data.badge_image_collection.map((badge, index) => (
                  <img key={index} src={badge.badge_image.url} alt={badge.badge_image.alt} className="w-6/12 w-cols-2" />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="heartbeat-section py-20 px-3 md:px-0">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
            <div className="xl:w-11/12 mx-auto">
              <div className="grid md:grid-cols-2 items-center">
                <div>
                  <p className="text-amazee-deep-sky-blue font-medium capitalize mb-0">{data.heart_beat_subtitle}</p>
                  <h2 className="font-semibold text-3xl md:text-4xl mt-2 mb-4">{data.heart_beat_title}</h2>
                  <div className="text-lg leading-8 font-light">
                    {RichTextRender(data.heart_beat_content)}
                  </div>
                  <div className="md:text-left">
                    <a href={`https://signup.amazee.io/trial`} className="">
                      <AmazeeIOButton classes="amazeeio-button-2 bg-transparent w-full md:w-auto">
                        {data.heart_beat_cta}
                      </AmazeeIOButton>
                    </a>
                  </div>
                </div>
                <div className="lg:w-10/12 mx-auto">
                  <img src={data.heart_beat_image.url} alt={data.heart_beat_image.alt} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="guaranty py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto w-11/12 lg:w-auto">
            <h2 className="mb-5 text-center text-3.5xl font-semibold leading-10">{data.guaranty_title}</h2>
            <p className="text-xl text-center xl:w-9/12 md:w-11/12 mx-auto font-light">{data.guaranty_subtitle}</p>

            <ul className="grid md:grid-cols-2 lg:gap-20 gap-10 mt-10 w-full xl:w-10/12 mx-auto">
              {data.guaranty_collection.map((guaranty, index) => (
                <li key={index} className="text-center flex flex-col items-center">
                  <img src={guaranty.guaranty_image.url} alt={guaranty.guaranty_image.alt} className="w-24 h-24" />
                  <h3 className="text-2xl font-semibold my-3">{guaranty.guaranty_item_title}</h3>
                  <p className="text-lg font-light mb-0">{guaranty.guaranty_item_description}</p>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="quote pb-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl relative mx-auto">
            <div className="w-11/12 mx-auto rounded-lg drupal-border  py-11">
            <img src={quoteIcon} alt="" className="absolute -top-5 mx-auto quotes" />
            <img src={quoteIcon} alt="" className="absolute -bottom-5 quotes second" />
            <div className="flex flex-col justify-center items-center lg:w-4/5 w-11/12 mx-auto text-center">
              <img src={data.quote_icon.url} alt={data.quote_icon.alt} className="object-contain lg:w-2/12 md:w-3/12 w-5/12" />
              <div className="text-xl lg:text-2xl mt-10 quote-body">
                {RichTextRender(data.quote_content)}
              </div>
              <p className="text-base md:text-lg font-medium">{data.quote_author}</p>
            </div>
            </div>
          </div>
        </section>

        <section className="get-started py-5">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto grid md:grid-cols-2 gap-x-8 items-center justify-center md:justify-items-start">
            <img src={data.get_started_image.url} alt={data.get_started_image.alt} />
            <div className="px-6 md:px-0">
              <h2 className="font-semibold text-2xl md:text-3.5xl text-white">{data.get_started_heading}</h2>
              <ul className="lg:my-10 my-3">
                {data.get_started_list_collection.map((list, index) => (
                  <li key={index} className="text-white lg:text-xl mb-3 flex gap-x-1 items-center">
                    <div className="tick mr-2 rounded-full p-1">
                      <Tick />
                    </div>
                    <p className="mb-0">{list.get_started_list_title}</p>
                  </li>
                ))}
              </ul>
              <a href={`https://signup.amazee.io/trial`} className="text-center md:text-left">
                <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                  {data.get_started_cta}
                </AmazeeIOButton>
              </a>
            </div>
          </div>
        </section>

        <section className="service-edge py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
            <div className="w-11/12 mx-auto grid md:grid-cols-2 xl:gap-x-16 md:gap-x-8 items-center justify-center md:justify-items-start">
              <div className="">
                <p className="text-amazee-deep-sky-blue mb-0 capitalize font-medium">{data.service_edge_subtitle.toLowerCase()}</p>
                <h2 className="font-semibold text-2xl md:text-3.5xl mt-2 mb-3">{data.service_edge_title}</h2>
                <div className="md:text-lg md:leading-8 font-light">
                  {RichTextRender(data.service_edge_content)}
                </div>
              </div>
              <div className="bg-white w-full service-edge-table pb-4 pt-2 drupal-border">
                <div className="">
                  <div className="mb-2">
                    <div className="px-6 border-b border-b-amazee-card-gray service-edge-table-header">
                      <p className="mb-0 font-semibold text-2xl text-capitalize pl-3 flex items-center">{data.service_edge_table_heading}</p>
                    </div>
                  </div>
                  <ul className="px-3">
                    {data.service_edge_table.map((list, index) => (
                      <li key={index} className="py-2 xl:px-4 px-2 flex justify-between items-center gap-1">
                        <p className="text-base lg:text-lg mb-0 md:w-11/12">{list.service_edge_table_list}</p>
                        <span className="hidden md:block mb-0 w-7 h-7">
                          <TickCircle />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="compliance py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
            <div className="w-11/12 mx-auto grid md:grid-cols-2 xl:gap-x-16 md:gap-x-8 items-center justify-center md:justify-items-start">
              <div className="bg-white w-full compliance-table pb-4 pt-2 drupal-border">
                <div className="">
                  <div className="mb-2">
                    <div className="px-6 border-b border-b-amazee-card-gray compliance-table-header">
                      <p className="mb-0 font-semibold text-2xl pl-3 flex items-center">{data.compliance_table_heading}</p>
                    </div>
                  </div>
                  <ul className="px-3">
                    {data.compliance_table.map((list, index) => (
                      <li key={index} className="py-2 xl:px-4 px-2 flex gap-1 justify-between items-center">
                        <p 
                          className={`text-base lg:text-lg mb-0 text-black ${list.compliance_table_check_mark ? 'w-auto' : 'md:w-10/12'}`}>{list.compliance_table_list}
                        </p>
                        <p className="mb-0 text-amazee-deep-sky-blue">
                          {list.compliance_table_check_mark ? list.compliance_table_check_mark : 
                          <span className="w-7 h-7 hidden md:inline-block">
                            <TickCircle />
                          </span>}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="side-content px-3 md:px-0">
                <p className="text-amazee-deep-sky-blue mb-0 capitalize font-medium">{data.compliance_subtitle.toLowerCase()}</p>
                <h2 className="font-semibold text-2xl md:text-3.5xl mt-2 mb-3">{data.compliance_title}</h2>
                <div className="md:text-lg md:leading-8 font-light">
                  {RichTextRender(data.compliance_content)}
                </div>
                <div>
                  <a href="https://trust.amazee.io/" className="">
                    <AmazeeIOButton classes="amazeeio-button-2 bg-transparent w-full md:w-auto">
                      {data.compliance_cta}
                    </AmazeeIOButton>
                  </a>
                </div>
                <div className="flex gap-5 flex-wrap mt-5 lg:w-8/12">
                  {data.compliance_images_collection.map((compliance, index) => (
                    <img key={index} src={compliance.compliance_image.url} alt={compliance.compliance_image.alt} className="w-12 object-contain" />
                  ))}
                </div>
            </div>
            </div>
          </div>
        </section>

        <section className="guaranty2 pb-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto w-11/12 lg:w-auto">
            <h2 className="mb-5 text-center text-3.5xl font-semibold leading-10">{data.guaranty2_title}</h2>
            <p className="text-xl text-center xl:w-9/12 md:w-11/12 mx-auto font-light">{data.guaranty2_subtitle}</p>

            <ul className="grid md:grid-cols-2 xl:gap-20 gap-10 mt-10 w-full xl:w-10/12 mx-auto">
              {data.guaranty2_items_collection.map((guaranty, index) => (
                <li key={index} className="text-center flex flex-col items-center justify-start">
                  <img src={guaranty.guaranty2_image.url} alt={guaranty.guaranty2_image.alt} className="w-24 h-24" />
                  <h3 className="text-2xl font-semibold my-3">{guaranty.guaranty2_item_title}</h3>
                  <p className="text-lg font-light mb-0">{guaranty.guaranty2_item_description}</p>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="multisite py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
            <div className="w-11/12 mx-auto grid md:grid-cols-2 gap-x-8 items-center justify-center md:justify-items-start">
              <div>
                <p className="mb-0 text-amazee-deep-sky-blue capitalize font-medium">{data.multisite_drupal_subtitle.toLowerCase()}</p>
                <h2 className="font-semibold text-2xl md:text-3.5xl text-white mt-2 md:mb-5 mb-2">{data.multisite_drupal_title}</h2>
                <div className="text-lg leading-8 md:mb-10 mb-5 text-white font-light multi-site-content">
                  {RichTextRender(data.multisite_drupal_content)}
                </div>
                <Link to={`/drupal-multisite-hosting`} className="text-center md:text-left">
                  <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto">
                    {data.multisite_drupal_cta}
                  </AmazeeIOButton>
                </Link>
              </div>
              <img src={data.multisite_drupal_image.url} alt={data.multisite_drupal_image.alt} className="w-10/12 md:mx-auto mx-0" />
            </div>
          </div>
        </section>

        <section className="edge-protection py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
            <div className="w-11/12 mx-auto grid md:grid-cols-2 xl:gap-x-16 md:gap-x-8 items-center justify-center md:justify-items-start">
              <div className="bg-white w-full edge-protection-table py-2 drupal-border">
                <div className="">
                  <div className="mb-2">
                    <div className="px-6 border-b border-b-amazee-card-gray edge-protection-table-header">
                      <p className="mb-0 font-semibold text-2xl capitalize pl-3 flex items-center">{data.edge_protection_table_name}</p>
                    </div>
                  </div>
                  <ul className="px-3">
                    {data.edge_protection_table.map((list, index) => (
                      <li key={index} className="py-2 xl:px-4 px-2 flex gap-1 justify-between items-center">
                        <p 
                          className={`text-base lg:text-lg mb-0 md:w-10/12 text-black`}>{list.edge_protection_table_list}
                        </p>
                        <p className="mb-0 w-7 h-7 hidden md:inline-block">
                          <TickCircle />
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="side-content px-3 md:px-0">
                <p className="text-amazee-deep-sky-blue mb-0 font-medium">{data.edge_protection_subtitle}</p>
                <h2 className="font-semibold text-2xl md:text-3.5xl mt-2 mb-3">{data.edge_protection_title}</h2>
                <div className="md:text-lg md:leading-8 font-light">
                  {RichTextRender(data.edge_protection_content)}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonial py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto py-11">
            <div className="w-11/12 mx-auto relative rounded-lg drupal-border py-11">
              <img src={quoteIcon} alt="" className="absolute -top-5 quotes" />
              <img src={quoteIcon} alt="" className="absolute -bottom-5 quotes second" />
              <div className="flex flex-col justify-center items-center lg:w-4/5 w-11/12 mx-auto text-center">
                <img src={data.testimonial_icon.url} alt={data.testimonial_icon.alt} className="object-contain lg:w-2/12 md:w-3/12 w-5/12" />
                <div className="text-xl lg:text-2xl mt-10 quote-body">
                  {RichTextRender(data.testimonial_content)}
                </div>
                <p className="text-base md:text-lg font-medium">{data.testimonial_author}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="get-in-touch py-20">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl relative mx-auto flex flex-col items-center justify-center">
            <div className="w-11/12 mx-auto">
              <div className="text-2xl md:text-3.5xl text-white text-center get-in-touch-content font-light">
                {RichTextRender(data.get_in_touch_content)}
              </div>
              <div className="flex justify-center">
                <Link to={`/get-in-touch`} className="">
                  <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                    {data.get_in_touch_cta}
                  </AmazeeIOButton>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="resources py-20">
        <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl mx-auto">
          <div className="w-11/12 mx-auto md:w-full">
            <h2 className="mb-10 text-center font-semibold md:text-3.5xl text-3xl">{data.resource_title}</h2>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 resource-card">
              {data.resources_collection.map((resource, index) => (
                <article key={index} className="rounded-xl bg-amazee-solitude h-auto">
                  <a href={resource.resource_item.document.data.link.url} className="hover:no-underline hover:text-current flex flex-col justify-between h-full">
                    <img src={resource.resource_item.document.data.image.url} alt={resource.resource_item.document.data.image.alt} className="object-cover rounded-t-xl" />
                    <div className="resource-card-content h-full flex flex-col justify-between">
                      <div>
                        <p className="text-xl font-medium resource-card-company-name capitalize">{resource.resource_item.document.data.company_name} Case Study</p>
                        <h3 className="text-2xl font-semibold mb-3">{resource.resource_item.document.data.title}</h3>
                        <div className="line-clamp font-light text-xl resource-card-description">
                          {RichTextRender(resource.resource_item.document.data.description)}
                        </div>
                      </div>
                      <div>
                        <a href={resource.resource_item.document.data.link.url} className="text-amazee-deep-sky-blue read-more-link hover:no-underline hover:text-amazee-deep-sky-blue ml-0 mb-0 w-full inline-block">Read More</a>
                      </div>
                    </div>
                  </a>
                </article>
              ))}
            </div>
          </div>
        </div>
        </section>
      </main>
    </AmazeePage>
  )
}

const DrupalPageQuery = graphql`
  {
    allPrismicDrupalLandingPage {
    edges {
      node {
        data {
          badge_image_collection {
            badge_image {
              alt
              url
            }
          }
          badge_background
          badge_heading
          compliance_content {
            text
            html
            raw
          }
          compliance_cta
          compliance_images_collection {
            compliance_image {
              alt
              url
            }
          }
          compliance_subtitle
          compliance_table {
            compliance_table_list
            compliance_table_check_mark
          }
          compliance_table_heading
          compliance_title
          edge_protection_content {
            text
            html
            raw
          }
          edge_protection_subtitle
          edge_protection_table {
            edge_protection_table_list
          }
          edge_protection_table_name
          edge_protection_title
          get_in_touch_content {
            text
            html
            raw
          }
          get_in_touch_cta
          get_started_cta
          get_started_heading
          get_started_image {
            alt
            url
          }
          get_started_list_collection {
            get_started_list_title
          }
          guaranty2_items_collection {
            guaranty2_image {
              alt
              url
            }
            guaranty2_item_description
            guaranty2_item_title
          }
          guaranty2_subtitle
          guaranty2_title
          guaranty_collection {
            guaranty_image {
              alt
              url
            }
            guaranty_item_description
            guaranty_item_title
          }
          guaranty_subtitle
          guaranty_title
          heart_beat_content {
            text
            html
            raw
          }
          heart_beat_cta
          heart_beat_image {
            alt
            url
          }
          heart_beat_subtitle
          heart_beat_title
          hero_cta
          hero_features {
            hero_feature_list
          }
          hero_heading
          hero_image {
            alt
            url
          }
          hero_image1 {
            alt
            url
          }
          hero_subheading
          meta_title
          multisite_drupal_content {
            text
            html
            raw
          }
          multisite_drupal_cta
          multisite_drupal_image {
            alt
            url
          }
          multisite_drupal_subtitle
          multisite_drupal_title
          page_meta_description
          page_meta_thumbnail {
            alt
            url
          }
          quote_author
          quote_content {
            text
            html
            raw
          }
          quote_icon {
            alt
            url
          }
          service_edge_content {
            text
            html
            raw
          }
          service_edge_subtitle
          service_edge_table {
            service_edge_table_list
          }
          service_edge_table_heading
          service_edge_title
          subtitle
          testimonial_author
          testimonial_content {
            text
            html
            raw
          }
          testimonial_icon {
            alt
            url
          }
          title
          resource_title
          resources_collection {
            resource_item {
              document {
                ... on PrismicResource {
                  id
                  data {
                    image {
                      url
                      alt
                    }
                    title
                    description {
                      html
                      raw
                      text
                    }
                    company_name
                    link {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
  `;

export default DrupalLandingPage