import React from 'react'

export const Tick = ({ width=20, height=20 }) => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20"  xmlSpace="preserve">
    <path fill="#00B6ED" d="M8.29,14.64c-0.38,0-0.76-0.15-1.06-0.43l-2.87-2.82c-0.59-0.58-0.6-1.53-0.01-2.12 c0.58-0.59,1.53-0.6,2.12-0.02l1.81,1.78l5.23-5.23c0.59-0.59,1.54-0.59,2.12,0s0.59,1.54,0,2.12L9.35,14.2 C9.06,14.5,8.67,14.64,8.29,14.64z"/>
  </svg>
)

export const TickCircle = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 29.5 29.1">
    <defs>
    </defs>
    <g>
      <g>
        <circle fill="#17b5ea" cx="14.8" cy="14.5" r="14"/>
      </g>
      <g>
        <path fill="#fff" d="M12.5,19.2c-.3,0-.5,0-.7-.3l-3-3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l2.3,2.3,6.7-6.6c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-7.4,7.3c-.2.2-.4.3-.7.3Z"/>
      </g>
    </g>
  </svg>

)

export const LockIcon = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" width={28} height={28} viewBox="0 0 28 28">
    <path fill="#00b6ed" d="M14,0C6.27,0,0,6.27,0,14s6.27,14,14,14,14-6.27,14-14S21.73,0,14,0ZM14,26c-6.62,0-12-5.38-12-12S7.38,2,14,2s12,5.38,12,12-5.38,12-12,12ZM10.04,10.1v1.39h-.09c-.58,0-1.05.47-1.05,1.05v5.8c0,.58.47,1.05,1.05,1.05h8.1c.58,0,1.05-.47,1.05-1.05v-5.8c0-.58-.47-1.06-1.05-1.06h-.03v-1.39c.03-2.2-1.72-4.02-3.93-4.05-2.2-.03-4.02,1.72-4.05,3.93,0,.04,0,.08,0,.13h0ZM14.62,15.7c-.07.05-.11.13-.1.21v1.28h0c.01.18-.08.35-.24.43-.24.13-.55.05-.68-.19-.04-.07-.06-.15-.06-.24h0v-1.28c0-.08-.03-.16-.1-.2-.38-.27-.52-.78-.32-1.2.22-.5.81-.73,1.31-.51.36.16.59.51.59.9.01.32-.14.62-.4.8h0ZM11.79,10.1c.03-1.24,1.05-2.22,2.29-2.2,1.2.03,2.17.99,2.2,2.2v1.39h-4.48v-1.39Z"/>
  </svg>
)


